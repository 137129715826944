import React, { FunctionComponent, useCallback, useEffect, useState } from 'react';
import TransferWizardModal, { TransferWizardStep } from './transfer-wizard-modal';
import {
  Balance,
  InvestmentTokenForInvestor,
  TokenTransfer,
  TokenTransfersApi,
  TokenTransferStatusEnum,
  TwoFactorAuthApi,
  Wallet,
} from 'api';
import { assertUnreachable } from 'ui/helper/unreachable';
import sleep from 'helper/sleep';
import WithTfaDevice from 'hoc/WithTfaDevice';
import useApiCall from 'hooks/use-api-call';
import { DepotTransferMaskFields } from './depot-transfer-input-mask';
import { convertWalletTypeFromApi } from 'core/api/conversions';
import useGoTo from 'hooks/use-go-to';
import { INVESTOR_ROUTES } from 'apps/investor/pages/routes.config';
import useTranslate from 'ui/hooks/use-translate';

interface TransferWizardProps {
  onClose: () => void;
  token: InvestmentTokenForInvestor;
  wallet: Wallet;
  transferId?: string;
  balance?: Balance;
}

const WAITING_TRANSFER_STATES: TokenTransferStatusEnum[] = [
  TokenTransferStatusEnum.PENDING,
  TokenTransferStatusEnum.TFA_REQUESTED,
];

const TransferWizard: FunctionComponent<TransferWizardProps> = ({
  onClose,
  wallet,
  token,
  transferId: initialTransferId,
  balance,
}) => {
  const { withApi, makeAuthenticatedApi, loading, error } = useApiCall(false);

  const translate = useTranslate();

  let whiteListedError;
  if (error?.getErrorCode('transfer_destination_product_unlisted')) {
    whiteListedError = error?.getMessage(translate);
  }

  const [step, setStep] = useState<TransferWizardStep>(TransferWizardStep.LOADING);
  const [transferId, setTransferId] = useState<string | undefined>(initialTransferId);
  const [amount, setAmount] = useState<string>();
  const [isPlatformWallet, setPlatformWallet] = useState('true');
  const [twoFactorRequestId, setTwoFactorRequestId] = useState<string | undefined>(undefined);

  const goToTransactions = useGoTo(INVESTOR_ROUTES.transactions);

  // Wait for loads and select first step
  useEffect(() => {
    if (step !== TransferWizardStep.LOADING) {
      return;
    }

    if (transferId) {
      // Shortcut to WAITING_FOR_CONFIRMATION step if transferId is provided via prop
      setStep(TransferWizardStep.WAITING_FOR_CONFIRMATION);
      return;
    }

    setStep(TransferWizardStep.DATA_INPUT);
  }, [token.id, transferId, step, wallet.id]);

  // Second step: form submission
  const onSubmit = useCallback(
    ({ to, amount }: DepotTransferMaskFields) => {
      const api: TokenTransfersApi = makeAuthenticatedApi(TokenTransfersApi);
      withApi(async () => {
        const transfer = await api.tokenTransfersManagedCreate({
          managedTokenTransferCreateRequest: {
            walletId: wallet.id,
            tokenId: token.id,
            to,
            amount,
            targetWalletIsUnmanaged: isPlatformWallet === 'false',
          },
        });
        setTransferId(transfer.id);
        setStep(TransferWizardStep.WAITING_FOR_CONFIRMATION);
      });
    },
    [makeAuthenticatedApi, token.id, withApi, wallet, isPlatformWallet],
  );

  const onChange = useCallback(
    ({ amount, isPlatformWallet }: DepotTransferMaskFields) => {
      setPlatformWallet(isPlatformWallet);
      setAmount(amount);
    },
    [amount, isPlatformWallet],
  );

  // Third step: Poll for confirmation
  useEffect(() => {
    if (step !== TransferWizardStep.WAITING_FOR_CONFIRMATION) {
      return;
    }
    if (!transferId) {
      throw new Error(`no transfer ID set for step ${step}`);
    }
    const running = [true];

    withApi(async () => {
      const api: TokenTransfersApi = makeAuthenticatedApi(TokenTransfersApi);

      for (let i = 0; running[0]; ++i) {
        await sleep(i >= 60 ? 10000 : 2000); // throttle polling after 1 minute

        let transfer: TokenTransfer;
        try {
          transfer = await api.tokenTransfersRetrieve({ id: transferId });
        } catch (_) {
          // keep polling
          continue;
        }

        if (!running[0] || step !== TransferWizardStep.WAITING_FOR_CONFIRMATION) {
          // Either we went to the next step manually (via cancel) or the component
          // was unmounted. Either way we don't want to touch any state
          break;
        }

        if (transfer.twoFactorRequestId) {
          setTwoFactorRequestId(transfer.twoFactorRequestId);
        }

        if (WAITING_TRANSFER_STATES.includes(transfer.status)) {
          // keep polling
          continue;
        } else if (
          transfer.status === TokenTransferStatusEnum.TFA_REJECTED ||
          transfer.status === TokenTransferStatusEnum.APPROVER_REJECTED
        ) {
          setStep(TransferWizardStep.REJECTED);
        } else {
          setStep(TransferWizardStep.COMPLETED);
        }
      }
    });

    return () => {
      running[0] = false;
    };
  }, [step, makeAuthenticatedApi, withApi, transferId]);

  // Cancellation, depending on step this does different things
  const onCancel = useCallback(() => {
    switch (step) {
      case TransferWizardStep.DATA_INPUT:
      case TransferWizardStep.REJECTED:
      case TransferWizardStep.COMPLETED: {
        onClose();
        return;
      }

      case TransferWizardStep.WAITING_FOR_CONFIRMATION: {
        // Cancel request manually
        if (!twoFactorRequestId) {
          throw new Error('two-factor request ID now known yet, should not be possible');
        }

        const api: TwoFactorAuthApi = makeAuthenticatedApi(TwoFactorAuthApi);
        withApi(async () => {
          await api.twoFactorAuthRequestsCancelCreate({
            id: twoFactorRequestId,
          });
          setStep(TransferWizardStep.REJECTED);
        });
        return;
      }

      case TransferWizardStep.LOADING: {
        throw new Error(`should not be able to cancel wizard in step ${step}`);
      }
    }
    assertUnreachable(step);
  }, [step, onClose, twoFactorRequestId, makeAuthenticatedApi, withApi]);

  // without network information, we shouldn't show anything, maybe better ux handling needed at this point
  if (!token.tokenizationInfo?.networkType || !balance) return null;

  return (
    <TransferWizardModal
      step={step}
      walletType={convertWalletTypeFromApi(wallet.type)}
      networkType={token.tokenizationInfo?.networkType}
      balance={balance}
      onCancel={onCancel}
      onSubmit={onSubmit}
      onChange={onChange}
      onGoToTransactions={goToTransactions}
      loading={loading}
      error={error}
      twoFactorRequestKnown={!!twoFactorRequestId}
      backToBackMinPrice={token.backToBackMinPrice}
      tokenId={token.id}
      amount={amount}
      isPlatformWallet={isPlatformWallet}
      whiteListedError={whiteListedError}
    />
  );
};

export default WithTfaDevice(TransferWizard);
