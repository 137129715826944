import { InvestmentTokenPermissionEnum } from 'api';
import type { InvestmentToken, InvestmentTokenForInvestor } from 'api';

type permissionsTokenType = InvestmentTokenForInvestor | InvestmentToken | null | undefined;
type permissionsTokenArrayType = InvestmentTokenForInvestor[] | InvestmentToken[] | null | undefined;

export const userHasPermission = (tokens: permissionsTokenArrayType, permission: InvestmentTokenPermissionEnum) =>
  !!tokens && tokens.some((token) => !!token?.permissions.find((perm) => perm === permission));

export const userHasPermissionForAllProducts = (
  tokens: permissionsTokenArrayType,
  permission: InvestmentTokenPermissionEnum,
) =>
  !!tokens &&
  (tokens as any[]).every(
    (token: permissionsTokenType) =>
      !!token?.permissions.find((perm: InvestmentTokenPermissionEnum) => perm === permission),
  );

export const currentTokenHasPermission = (token: permissionsTokenType, permission: InvestmentTokenPermissionEnum) =>
  !!token?.permissions.find((perm) => perm === permission);

export const hasViewPermission = (tokens: permissionsTokenArrayType) =>
  userHasPermission(tokens, InvestmentTokenPermissionEnum.VIEW_DATA);

export const hasManageCreditDigitalSecuritiesPermission = (tokens: permissionsTokenArrayType) =>
  userHasPermission(tokens, InvestmentTokenPermissionEnum.MANAGE_CREDIT_DIGITAL_SECURITIES);

export const hasManageDistributionPermission = (tokens: permissionsTokenArrayType) =>
  userHasPermission(tokens, InvestmentTokenPermissionEnum.MANAGE_DISTRIBUTION);

export const hasManageIncomingPaymentsPermission = (tokens: permissionsTokenArrayType) =>
  userHasPermission(tokens, InvestmentTokenPermissionEnum.MANAGE_INCOMING_PAYMENTS);

export const hasManageTriggerSolutionPermission = (tokens: permissionsTokenArrayType) =>
  userHasPermission(tokens, InvestmentTokenPermissionEnum.TRIGGER_SOLUTION);

export const hasManageApprovalsPermission = (tokens: permissionsTokenArrayType) =>
  userHasPermission(tokens, InvestmentTokenPermissionEnum.MANAGE_APPROVALS);

export const hasDownloadStatisticsPermission = (tokens: permissionsTokenArrayType) =>
  userHasPermission(tokens, InvestmentTokenPermissionEnum.DOWNLOAD_STATISTICS);

export const hasManageRedemptionsPermission = (tokens: permissionsTokenArrayType) =>
  userHasPermission(tokens, InvestmentTokenPermissionEnum.MANAGE_REDEMPTIONS);

export const hasViewPermissionForAllProducts = (tokens: permissionsTokenArrayType) =>
  userHasPermissionForAllProducts(tokens, InvestmentTokenPermissionEnum.VIEW_DATA);

export const hasManageCreditDigitalSecuritiesPermissionForAllProducts = (tokens: permissionsTokenArrayType) =>
  userHasPermissionForAllProducts(tokens, InvestmentTokenPermissionEnum.MANAGE_CREDIT_DIGITAL_SECURITIES);

export const hasManageDistributionPermissionForAllProducts = (tokens: permissionsTokenArrayType) =>
  userHasPermissionForAllProducts(tokens, InvestmentTokenPermissionEnum.MANAGE_DISTRIBUTION);

export const hasManageIncomingPaymentsPermissionForAllProducts = (tokens: permissionsTokenArrayType) =>
  userHasPermissionForAllProducts(tokens, InvestmentTokenPermissionEnum.MANAGE_INCOMING_PAYMENTS);

export const hasManageApprovalsPermissionForAllProducts = (tokens: permissionsTokenArrayType) =>
  userHasPermissionForAllProducts(tokens, InvestmentTokenPermissionEnum.MANAGE_APPROVALS);

export const hasDownloadStatisticsPermissionForAllProducts = (tokens: permissionsTokenArrayType) =>
  userHasPermissionForAllProducts(tokens, InvestmentTokenPermissionEnum.DOWNLOAD_STATISTICS);

export const hasManageRedemptionsPermissionForAllProducts = (tokens: permissionsTokenArrayType) =>
  userHasPermissionForAllProducts(tokens, InvestmentTokenPermissionEnum.MANAGE_REDEMPTIONS);

export const currentTokenHasViewPermission = (token: permissionsTokenType) =>
  currentTokenHasPermission(token, InvestmentTokenPermissionEnum.VIEW_DATA);

export const currentTokenHasManageCreditDigitalSecuritiesPermission = (token: permissionsTokenType) =>
  currentTokenHasPermission(token, InvestmentTokenPermissionEnum.MANAGE_CREDIT_DIGITAL_SECURITIES);

export const currentTokenHasManageDistributionPermission = (token: permissionsTokenType) =>
  currentTokenHasPermission(token, InvestmentTokenPermissionEnum.MANAGE_DISTRIBUTION);

export const currentTokenHasManageIncomingPaymentsPermission = (token: permissionsTokenType) =>
  currentTokenHasPermission(token, InvestmentTokenPermissionEnum.MANAGE_INCOMING_PAYMENTS);

export const currentTokenHasManageApprovalsPermission = (token: permissionsTokenType) =>
  currentTokenHasPermission(token, InvestmentTokenPermissionEnum.MANAGE_APPROVALS);

export const currentTokenHasDownloadStatisticsPermission = (token: permissionsTokenType) =>
  currentTokenHasPermission(token, InvestmentTokenPermissionEnum.DOWNLOAD_STATISTICS);

export const currentTokenHasManageRedemptionsPermission = (token: permissionsTokenType) =>
  currentTokenHasPermission(token, InvestmentTokenPermissionEnum.MANAGE_REDEMPTIONS);
