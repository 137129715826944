import React, { forwardRef, useState } from 'react';
import * as Styled from './styled';
import Tooltip from 'ui/atoms/tooltip';
import { ProgressStep } from 'core/layout/wizard/progress-steps';

export interface ProgressStepsHorizontalProps {
  steps: ProgressStep[];
}

const ProgressStepsHorizontal = forwardRef<HTMLDivElement, ProgressStepsHorizontalProps>((props, ref) => {
  const { steps } = props;

  const [stepHover, setStepHover] = useState(-1);

  return (
    <Styled.ProgressBar ref={ref}>
      <Styled.ProgressStepsMargin />
      {steps.map(({ id, title, tooltip, current, done, active, onClick }, index) => {
        return (
          <>
            <Tooltip content={tooltip}>
              <Styled.ProgressStepItem
                onMouseEnter={() => setStepHover(id)}
                onMouseLeave={() => setStepHover(-1)}
                key={index}
                onClick={onClick}
                $hover={stepHover === id}
                $done={!!done}
                $active={!!active}
                $current={current}
                $clickable={!!onClick}
              >
                <Styled.ProgressStepNumber
                  $done={!!done}
                  $active={!!active}
                  $current={current}
                  $clickable={!!onClick}
                  $hover={stepHover === id}
                >
                  {done ? <Styled.CheckIcon name="check" $done={!!done} $hover={stepHover === id} $current={current} $active={!!active} /> : index + 1}
                </Styled.ProgressStepNumber>
                <Styled.ProgressStepText>{title}</Styled.ProgressStepText>
              </Styled.ProgressStepItem>
            </Tooltip>
            {steps.length - 1 !== index && <Styled.ProgressStepSpace className="spacer" />}
          </>
        );
      })}
      <Styled.ProgressStepsMargin />
    </Styled.ProgressBar>
  );
});

export default ProgressStepsHorizontal;
