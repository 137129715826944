import React, { FunctionComponent } from 'react';

import Currency from 'ui/atoms/currency';
import Number from 'ui/atoms/number';
import Tag from 'ui/atoms/tag';
import Translate from 'ui/atoms/translate';
import InfoGrid, { InfoField } from 'ui/molecules/info-grid';
import { RuleType } from 'ui/types/investment';
import { camelCase } from 'change-case';
import { CountryEnum, InvestmentTokenForInvestor, Money } from 'api';
import Date from 'ui/atoms/date';
import useIsMedia, { DEVICES } from 'ui/hooks/use-is-media';

export interface ProductInvitationCardProps {
  id: string;
  minNumberOfTokens?: number;
  maxNumberOfTokens?: number;
  pricePerToken: Money;
  country?: CountryEnum;
  email?: string;
  status: 'new' | 'committed';
  ruleType: string;
  /** Additional classes. */
  className?: string;
  token?: InvestmentTokenForInvestor;
  name?: string;
  createdAt?: Date;
  signedPercentage: number;
  distributionPlatform: string;
}

// TODO(geforcefan): remove minimumTicketSizeAlternative translation, params needed for rule types
const ProductInvitationCard: FunctionComponent<ProductInvitationCardProps> = (props) => {
  const {
    id,
    minNumberOfTokens = 0,
    maxNumberOfTokens = 0,
    pricePerToken,
    country,
    email,
    status,
    ruleType,
    name,
    createdAt,
    signedPercentage,
    token,
    distributionPlatform,
  } = props;

  const isPhone = useIsMedia(DEVICES.phone);

  return (
    <InfoGrid columns={isPhone ? 1 : 3} truncate={true}>
      <InfoField title={<Translate name="issuerProductDetails.campaigns.campaignDetails.product" truncate={true} />}>
        {token?.name}
      </InfoField>
      <InfoField
        title={<Translate name="issuerProductDetails.campaigns.campaignDetails.personalInvitationId" truncate={true} />}
      >
        {id}
      </InfoField>
      <InfoField
        title={<Translate name="issuerProductDetails.campaigns.campaignDetails.typeOfOffering" truncate={true} />}
      >
        <Translate
          name={
            ruleType === RuleType.MinimumTicketSize
              ? `ruleType.minimumTicketSizeAlternative`
              : `ruleType.${camelCase(ruleType)}`
          }
        />
      </InfoField>
      <InfoField title={<Translate name="issuerProductDetails.campaigns.campaignDetails.reference" truncate={true} />}>
        {name}
      </InfoField>
      <InfoField title={<Translate name="issuerProductDetails.campaigns.campaignDetails.createdAt" truncate={true} />}>
        <Date>{createdAt}</Date>
      </InfoField>
      <InfoField
        title={<Translate name="issuerProductDetails.campaigns.campaignDetails.distributionPlatform" truncate={true} />}
      >
        {distributionPlatform}
      </InfoField>
      <InfoField title={<Translate name="investmentInvitation.pricePerToken" truncate={true} />}>
        <Currency decimals={'*'}>{pricePerToken}</Currency>
      </InfoField>
      <InfoField
        title={<Translate name="issuerProductDetails.campaigns.campaignDetails.allocation.title" truncate={true} />}
      >
        <Translate
          name="issuerProductDetails.campaigns.campaignDetails.allocation.content"
          args={[(_, key) => <Number key={key}>{maxNumberOfTokens}</Number>]}
        />
      </InfoField>
      <InfoField
        title={<Translate name="issuerProductDetails.campaigns.campaignDetails.investmentsRange" truncate={true} />}
      >
        <Translate
          name="studioCampaignInvestmentCard.nrOfTokenRange.content"
          args={[
            (_, key) => <Number key={key}>{minNumberOfTokens}</Number>,
            (_, key) => <Number key={key}>{maxNumberOfTokens}</Number>,
          ]}
        />
      </InfoField>
      {email && <InfoField title={<Translate name="common.email" />}>{email}</InfoField>}
      <InfoField title={<Translate name="issuerProductDetails.campaigns.campaignDetails.signed" truncate={true} />}>
        {`${signedPercentage.toFixed()}%`}
      </InfoField>
      {country && (
        <InfoField title={<Translate name="issuerProductDetails.campaigns.campaignDetails.market" truncate={true} />}>
          <Translate name={`countries.${country}`} />
        </InfoField>
      )}
      <InfoField title={<Translate name="common.status" truncate={true} />}>
        <Tag color="info" truncate={true} padded="tiny">
          <Translate name={`investmentInvitation.status.${status}`} />
        </Tag>
      </InfoField>
    </InfoGrid>
  );
};

export default ProductInvitationCard;
