import { resolveColor, getColorsMap } from 'ui/styles/utils/colors';
import styled, { DefaultTheme, ThemedStyledProps, css } from 'styled-components';
import Icon from 'ui/atoms/icon';

export interface ProgressItemProps {
  $clickable?: boolean;
  $active?: boolean;
  $current?: boolean;
  $done?: boolean;
  $hover?: boolean;
}

export const ProgressBar = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
`;

export const ProgressStepItem = styled.div<ProgressItemProps>`
  width: 2rem;
  color: ${({ theme, $active, $hover, $clickable }) =>
    $active || ($hover && $clickable) ? resolveColor(theme, 'marker') : resolveColor(theme, 'white')};
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  font-size: 12px;
  text-decoration: ${({ $clickable, $active, $current, $hover }) =>
    $clickable && ((!$active && !$current) || $hover) ? 'underline' : 'initial'};
  opacity: ${({ $clickable, $current, $active }) => ($clickable || ($current && !$active) ? '100%' : '50%')};
`;

function getProgressStepsBackground({ theme, $active, $current }: ThemedStyledProps<ProgressItemProps, DefaultTheme>) {
  return css<ProgressItemProps>`
    &::before {
      content: '';
      position: absolute;
      border-radius: 50%;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: -1;
      background-color: ${$active ? resolveColor(theme, 'marker') : resolveColor(theme, 'white')};
      opacity: ${$active || $current ? '100%' : '15%'};
    }
  `;
}

function getProgressStepsBackgroundHover({
  theme,
  $done,
  $active,
  $hover,
  $clickable,
}: ThemedStyledProps<ProgressItemProps, DefaultTheme>) {
  if (!$hover || !$clickable) return null;

  return css<ProgressItemProps>`
    &::before {
      background-color: ${$clickable ? resolveColor(theme, 'marker') : resolveColor(theme, 'white')};
      opacity: ${$done || $active || $clickable ? '100%' : '35%'};
    }
  `;
}

export const ProgressStepNumber = styled.p<ProgressItemProps>`
  position: relative;
  width: 2rem;
  height: 2rem;
  margin: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 0.5rem;
  cursor: pointer;

  ${({ theme, $active, $current, $hover }) => {
    const { minor } = getColorsMap(theme, 'marker');

    return css`
      color: ${$active || $hover ? minor : $current ? resolveColor(theme, 'marker') : resolveColor(theme, 'white')};
    `;
  }}

  ${getProgressStepsBackground};
  ${getProgressStepsBackgroundHover};
`;

export const ProgressStepSpace = styled.div`
  width: 100%;
  max-width: 72px;
  min-width: 42px;
`;

export const ProgressStepsMargin = styled.div`
  width: 100%;
  min-width: 72px;
`;

export const ProgressStepText = styled.span`
  width: 100%;
  min-width: 88px;
  font-weight: 700;
`;

export const CheckIcon = styled(Icon)<ProgressItemProps>`
  ${({ theme, $hover, $current, $active }) => {
    const { minor } = getColorsMap(theme, 'marker');

    return css`
      color: ${$active || $hover ? minor : $current ? resolveColor(theme, 'marker') : resolveColor(theme, 'white')};
      fill: ${$active || $hover ? minor : $current ? resolveColor(theme, 'marker') : resolveColor(theme, 'white')};
    `;
  }}
`;
