import React, {
  createContext,
  Dispatch,
  FunctionComponent,
  SetStateAction,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { nullMoney } from 'ui/helper/money';
import { InvestmentTokenForInvestor, StepEnum, Money } from 'api';
import { convertProductTypeFromApi } from 'core/api/conversions';
import useInvestment from 'hooks/use-investment';
import WizardContext from 'libraries/wizard/wizard-context';
import InvestmentContent from 'libraries/wizard/wizard-content';
import InvestmentSideContent from 'src/subapps/investment/pages/investment/side-content/investment-side-content';
import LossAbilityCard from './loss-ability-card';

export interface InvestmentSummaryNumbers {
  /** Investment amount */
  investmentTotal: Money | null;

  /** Payment amount */
  paymentTotal: Money | null;

  /** Disagio amount */
  disagio: Money | null;

  /** Accrued interest */
  accruedInterest: Money;

  agio: Money | null;

  voucherName?: string;
}

interface InvestmentSummaryContextProps {
  investmentSummaryNumbers: InvestmentSummaryNumbers;
  setInvestmentSummaryNumbers: Dispatch<SetStateAction<InvestmentSummaryNumbers>>;
}

interface InvestmentSummaryProps {
  token?: InvestmentTokenForInvestor;
  initialData: InitialDataInvestmentSummary;
  step?: StepEnum;
}

export const InvestmentSummaryContext = createContext<InvestmentSummaryContextProps>({
  investmentSummaryNumbers: {
    investmentTotal: null,
    paymentTotal: null,
    disagio: null,
    agio: null,
    accruedInterest: nullMoney,
    voucherName: '',
  },
  setInvestmentSummaryNumbers: () => {},
});

export interface InitialDataInvestmentSummary extends InvestmentSummaryNumbers {
  productName: string;
  pricePerToken: Money;
}

const InvestmentSummary: FunctionComponent<InvestmentSummaryProps> = (props) => {
  const { children, step, token, initialData } = props;
  const { investmentId, isTransferInvitation } = useContext(WizardContext);
  const { investment } = useInvestment(investmentId);

  const isCommitted = useMemo(() => !!investmentId, [investmentId]);
  const submittedInvestmentSummaryNumbers: InvestmentSummaryNumbers = useMemo(() => {
    if (isCommitted && investment) {
      return {
        investmentTotal: investment.investmentTotal,
        paymentTotal: investment.paymentTotal,
        accruedInterest: investment.accruedInterest,
        disagio: investment.disagio,
        agio: investment.agio,
      };
    }
    return {
      investmentTotal: initialData.investmentTotal,
      paymentTotal: initialData.paymentTotal,
      accruedInterest: initialData.accruedInterest,
      disagio: initialData.disagio,
      agio: initialData.agio,
    };
  }, [
    initialData,
    isCommitted,
    investment?.investmentTotal,
    investment?.paymentTotal,
    investment?.accruedInterest,
    investment?.disagio,
    investment?.agio,
  ]);

  const [investmentSummaryNumbers, setInvestmentSummaryNumbers] = useState<InvestmentSummaryNumbers>(
    submittedInvestmentSummaryNumbers,
  );

  // when step changes, use submitted investment data instead of the dynamically updated data
  useEffect(() => {
    setInvestmentSummaryNumbers(submittedInvestmentSummaryNumbers);
  }, [step, submittedInvestmentSummaryNumbers]);

  return (
    <InvestmentSummaryContext.Provider
      value={{
        investmentSummaryNumbers,
        setInvestmentSummaryNumbers,
      }}
    >
      <InvestmentContent
        sideContent={
          token &&
          // either commitment was not yet done -> no investment exists
          // or commitment was done -> investment should exist and be loaded
          (!isCommitted || investment) &&
          !isTransferInvitation && (
            <>
              <InvestmentSideContent
                image={token.productImage}
                showPaymentListing={
                  !!step && !([StepEnum.ACCOUNT_SETUP, StepEnum.UNCONFIRMED] as StepEnum[]).includes(step)
                }
                useUnits={token.useUnits}
                productName={initialData.productName}
                productType={convertProductTypeFromApi(token.productType)}
                tokenPrice={initialData.pricePerToken}
                isAccruedInterestFixed={!!investment?.acceptedDate}
                paymentCompleted={!!investment?.paymentDate}
                showAccruedInterest={token.calculateAccruedInterest}
                {...investmentSummaryNumbers}
              />
              {token.isEcsp && (step === StepEnum.INVESTMENT_AMOUNT || step === StepEnum.QUESTIONNAIRE) && (
                <LossAbilityCard />
              )}
            </>
          )
        }
      >
        {children}
      </InvestmentContent>
    </InvestmentSummaryContext.Provider>
  );
};

export default InvestmentSummary;
