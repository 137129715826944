import React, { FunctionComponent, useState } from 'react';
import Header from 'ui/atoms/header';
import Translate from 'ui/atoms/translate';
import Segment from 'ui/atoms/segment';
import Currency from 'ui/atoms/currency';
import BoxedContent from 'ui/molecules/boxed-content';
import InfoGrid, { InfoField } from 'ui/molecules/info-grid';
import { camelCase } from 'change-case';
import { AdminTokenOverview } from 'api';
import { useTranslateWithStringArgs } from 'ui/hooks/use-translate';
import PlaceholderFallback from 'ui/atoms/placeholder-fallback';
import Grid, { Col } from 'ui/atoms/grid';
import * as Styled from './styled';
import CopyButton from 'ui/molecules/copy-button';
import Spacer from 'ui/atoms/spacer';
import Accordion from 'src/ui/atoms/accordion';

export interface ProductOverviewProps {
  data: AdminTokenOverview;
}

const ProductOverview: FunctionComponent<ProductOverviewProps> = (props) => {
  const { data } = props;

  const [isAccordionExpanded, setAccordionExpanded] = useState(false);

  const translateWithStringArgs = useTranslateWithStringArgs();

  return (
    <>
      <BoxedContent releaseSpaceWhenTitleIsEmpty={true}>
        <InfoGrid columns={3}>
          <InfoField title={<Translate name="dashboardIssuerOverview.productType" />}>
            <Translate name={`productType.${camelCase(data.productType)}`} />
          </InfoField>
          <InfoField title={<Translate name="dashboardIssuerOverview.totalVolume" />}>
            <Currency>{data.totalVolume}</Currency>
          </InfoField>
          <InfoField title={<Translate name="dashboardIssuerOverview.issuer" />}>{data.companyName}</InfoField>
          <InfoField title={<Translate name="dashboardIssuerOverview.network" />}>
            {data.isTokenized ? (
              <>
                <Styled.Network>{data.network}</Styled.Network>
                (<Translate name="dashboardIssuerOverview.tokenized" />)
              </>
            ) : (
              <Translate name="dashboardIssuerOverview.notTokenized" />
            )}
          </InfoField>
          <InfoField title={<Translate name="entryType.label" />}>
            <Translate name={`entryType.${data.entryType}`} />
          </InfoField>
        </InfoGrid>
        <Accordion
          label={translateWithStringArgs('dashboardIssuerOverview.tokenizationInfo')}
          expanded={isAccordionExpanded}
          onChange={() => setAccordionExpanded(!isAccordionExpanded)}
        >
          <Grid>
            <Col width={4}>
              <Translate name="dashboardIssuerOverview.smartContract" />
            </Col>
            <Styled.Column width={8} spacing="large">
              <Styled.TruncateContractInfo>
                <PlaceholderFallback>{data.smartContractAddress}</PlaceholderFallback>
              </Styled.TruncateContractInfo>
              {data.smartContractAddress && (
                <>
                  <Spacer x={1} />
                  <CopyButton valueToCopy={data.smartContractAddress} />
                </>
              )}
            </Styled.Column>
          </Grid>
          <Grid>
            <Col width={4}>
              <Translate name="dashboardIssuerOverview.paperContract" />
            </Col>
            <Col width={8}>
              <Styled.TruncateContractInfo>
                <PlaceholderFallback>{data.paperContractHash}</PlaceholderFallback>
              </Styled.TruncateContractInfo>
            </Col>
          </Grid>
        </Accordion>
      </BoxedContent>
      <div>
        <Grid spacing="large">
          <Col width={4} tablet={12} phone={12} spacing="medium">
            <Segment raised={true}>
              <Header size="tiny" color="grayDark" spacing="medium" truncate={true} uppercase={true}>
                <Translate name="dashboardIssuerOverview.investments" />
              </Header>
              <Styled.HeaderRow>{data?.numberActiveInvestments}</Styled.HeaderRow>
            </Segment>
          </Col>
          <Col width={4} tablet={12} phone={12} spacing="medium">
            <Segment raised={true}>
              <Header size="tiny" color="grayDark" spacing="medium" truncate={true} uppercase={true}>
                <Translate name="dashboardIssuerOverview.totalAmount" />
              </Header>
              <Styled.HeaderRow>
                <Currency>{data?.sumActiveInvestments}</Currency>
              </Styled.HeaderRow>
            </Segment>
          </Col>
          <Col width={4} tablet={12} phone={12} spacing="medium">
            <Segment raised={true}>
              <Header size="tiny" color="grayDark" spacing="medium" truncate={true} uppercase={true}>
                <Translate name="dashboardIssuerOverview.sumPaymentsReceived" />
              </Header>
              <Styled.HeaderRow>
                <Currency>{data?.sumPaymentsReceived}</Currency>
              </Styled.HeaderRow>
            </Segment>
          </Col>
        </Grid>
      </div>
    </>
  );
};

export default ProductOverview;
