/* CP-127 */
import { useCallback, useEffect, useMemo, useState } from 'react';

import useApiCall from './use-api-call';

import { Investor, Issuer, InvestmentTokenForInvestor, StepEnum, InvestmentDetails, FrontendStatusEnum } from 'api';

import { InvestmentsApi, InvestorsApi, WizardApi } from 'api';

import { getInvestorInvestmentStatus } from 'helper/investment-status';
import { AnyServerError } from './use-api-error';
import { InvestorInvestmentStatusEnum } from 'ui/types/investment';
import { getWalletAddressForNetwork } from 'helper/network';
import { toNumber } from 'ui/helper/money';

/**
 * Specific to the investor who started the investment, cannot be used by admins
 * @param investmentId: string
 */
const useInvestment = (
  investmentId: string,
): {
  investment: InvestmentDetails | null;
  investor: Investor | null;
  token: InvestmentTokenForInvestor | null;
  issuer: Issuer | null;
  invitationId: string | null;
  step: StepEnum | null;
  investorStatus: InvestorInvestmentStatusEnum | null;
  issuerStatus: FrontendStatusEnum | null;
  error: AnyServerError | undefined;
  reload: () => void;
  loading: boolean;
} => {
  const { withApi, makeAuthenticatedApi, error, loading } = useApiCall(true);

  const [investment, setInvestment] = useState<InvestmentDetails | null>(null);
  const [token, setToken] = useState<InvestmentTokenForInvestor | null>(null);
  const [issuer, setIssuer] = useState<Issuer | null>(null);
  const [investor, setInvestor] = useState<Investor | null>(null);
  const [invitationId, setInvitationId] = useState<string | null>(null);
  const [step, setStep] = useState<StepEnum | null>(null);
  const [investorStatus, setInvestorStatus] = useState<InvestorInvestmentStatusEnum | null>(null);
  const [issuerStatus, setIssuerStatus] = useState<FrontendStatusEnum | null>(null);

  // TODO(geforcefan): we need to cancel loadInvestmentData when recalling it, this
  //  is causing serious problems
  const loadInvestmentData = useCallback(() => {
    (async () => {
      const wizardApi: WizardApi = await makeAuthenticatedApi(WizardApi);
      const investmentsApi: InvestmentsApi = await makeAuthenticatedApi(InvestmentsApi);
      const investorApi: InvestorsApi = await makeAuthenticatedApi(InvestorsApi);

      await withApi(async () => {
        const investment = await investmentsApi.investmentsRetrieve({
          id: investmentId,
        });
        const investor = await investorApi.investorsRetrieve({
          id: investment.investor.id,
        });
        const wizard = await wizardApi.wizardRetrieve({ code: investmentId });
        const token = investment.token;

        if (!token.issuer) {
          throw new Error(`token ${token.id} does not have an issuer`);
        }

        setIssuer(token.issuer);

        setInvestor(investor);
        setInvestment(investment);
        setToken(token);
        setStep(wizard.step || null);
        setInvitationId(wizard.invitation || null);
      });
    })();
  }, [investmentId, makeAuthenticatedApi, withApi]);

  useEffect(() => {
    loadInvestmentData();
  }, [loadInvestmentData]);

  useEffect(() => {
    if (!step || !investment || !token) return;

    const hasWalletAddress = !!(
      investment.wallet &&
      token.tokenizationInfo &&
      getWalletAddressForNetwork(token.tokenizationInfo.networkType, investment.wallet)
    );

    setInvestorStatus(
      getInvestorInvestmentStatus({
        step,
        hasTokenTransactionHash: !!investment.tokenTransactionHash,
        isWalletManaged: investment.wallet?.managed,
        hasWalletAddress: hasWalletAddress,
        isTokenized: investment.isTokenized,
        investmentTotal: toNumber(investment?.investmentTotal),
        investmentTotalFulfilled: toNumber(investment?.investmentTotalFulfilled),
        acquisitionType: investment.acquisitionType,
        isRedeemed: investment.redeemed,
      }),
    );
    setIssuerStatus(investment?.frontendStatus);
  }, [step, investment, token]);

  return useMemo(
    () => ({
      investment,
      token,
      issuer,
      investor,
      invitationId,
      step,
      investorStatus,
      issuerStatus,
      error,
      reload: loadInvestmentData,
      loading,
    }),
    [
      investment,
      token,
      issuer,
      investor,
      invitationId,
      step,
      investorStatus,
      issuerStatus,
      error,
      loading,
      loadInvestmentData,
    ],
  );
};

export default useInvestment;
