import React, { FunctionComponent, useCallback, useEffect, useMemo, useState } from 'react';
import { FilterDateOption, FilterInputOption, FilterPopup, FilterRange, Search } from 'ui/molecules/filtering';
import { useTranslateWithStringArgs } from 'ui/hooks/use-translate';
import { useFilterValue } from 'ui/molecules/filtering/hooks';
import FilterMultiGroupOption from 'ui/molecules/filtering/filter-popup/filter-multi-group-option';
import { useProducts, useProductsFilter } from 'apps/issuer/hooks';
import FilterMultiSelect from 'ui/molecules/filtering/filter-popup/filter-multi-select';
import useApiCall from 'src/hooks/use-api-call';
import { DistributionPlatformsApi } from 'src/api';

interface DistributionPlatformFilter {
  value: string;
  label: string;
  name: string;
}

export const IssuerInvestmentsFilters: FunctionComponent = () => {
  const translate = useTranslateWithStringArgs();

  const { tokens } = useProducts();
  const { productsFilter } = useProductsFilter(tokens);

  const [distributionPlatforms, setDistributionPlatforms] = useState<DistributionPlatformFilter[]>([]);

  const { withApi, makeAuthenticatedApi } = useApiCall();

  const distributionApi: DistributionPlatformsApi = useMemo(
    () => makeAuthenticatedApi(DistributionPlatformsApi),
    [makeAuthenticatedApi],
  );

  const getDistributionPlatformName = useCallback(() => {
    withApi(async () => {
      try {
        const response = await distributionApi.distributionPlatformsList();
        setDistributionPlatforms(
          response.map((item) => ({
            value: item.id,
            label: item.platformName,
            name: item.platformName,
          })),
        );
      } catch (e) {
        console.error(e);
      }
    });
  }, [distributionApi]);

  useEffect(() => {
    getDistributionPlatformName();
  }, [withApi, distributionApi]);

  const investmentStatusFilterOptions = [
    {
      value: ['IdentificationPending'],
      label: translate('issuerInvestmentStatus.identificationPending.status'),
      name: 'IdentificationPending',
    },
    {
      value: ['IdentificationCompliancePending'],
      label: translate('issuerInvestmentStatus.identificationCompliancePending.status'),
      name: 'IdentificationCompliancePending',
    },
    {
      value: ['AcceptanceMissing'],
      label: translate('issuerInvestmentStatus.acceptanceMissing.status'),
      name: 'AcceptanceMissing',
    },
    {
      value: ['PaymentPending'],
      label: translate('issuerInvestmentStatus.paymentPending.status'),
      name: 'PaymentPending',
    },
    {
      value: ['WalletSetupPending'],
      label: translate('issuerInvestmentStatus.walletSetupPending.status'),
      name: 'WalletSetupPending',
    },
    {
      value: ['AdditionalInformationPending'],
      label: translate('issuerInvestmentStatus.additionalInformationPending.status'),
      name: 'AdditionalInformationPending',
    },
    {
      value: ['Finished'],
      label: translate('issuerInvestmentStatus.finished.status'),
      name: 'Finished',
    },
    {
      value: ['SecuritiesTransferred'],
      label: translate('issuerInvestmentStatus.securitiesTransferred.status'),
      name: 'SecuritiesTransferred',
    },
    {
      value: ['SuccessFullyTransfered'],
      label: translate('issuerInvestmentStatus.successFullyTransfered.status'),
      name: 'SuccessFullyTransfered',
    },
    {
      value: ['SuccessPartiallyTransfered'],
      label: translate('issuerInvestmentStatus.successPartiallyTransfered.status'),
      name: 'SuccessPartiallyTransfered',
    },
    {
      value: ['TransactionSucceeded'],
      label: translate('issuerInvestmentStatus.transactionSucceeded.status'),
      name: 'TransactionSucceeded',
    },
    {
      value: ['TransactionPending'],
      label: translate('issuerInvestmentStatus.transactionPending.status'),
      name: 'TransactionPending',
    },
    {
      value: ['TransactionFailed'],
      label: translate('issuerInvestmentStatus.transactionFailed.status'),
      name: 'Failed',
    },
    {
      value: ['SecurityTransferMissing'],
      label: translate('issuerInvestmentStatus.securityTransferMissing.status'),
      name: 'SecurityTransferMissing',
    },
    {
      value: ['SuccessRedeemed'],
      label: translate('issuerInvestmentStatus.successRedeemed.status'),
      name: 'SuccessRedeemed',
    },
    {
      value: ['CanceledKycInsufficient'],
      label: translate('issuerInvestmentStatus.canceledKycInsufficient.status'),
      name: 'CanceledKycInsufficient',
    },
    {
      value: ['CanceledPaymentMissing'],
      label: translate('issuerInvestmentStatus.canceledPaymentMissing.status'),
      name: 'CanceledPaymentMissing',
    },
    {
      value: ['Aborted'],
      label: translate('issuerInvestmentStatus.aborted.status'),
      name: 'Aborted',
    },
    {
      value: ['Rejected'],
      label: translate('issuerInvestmentStatus.rejected.status'),
      name: 'Rejected',
    },
    {
      value: ['Handover'],
      label: translate('issuerInvestmentStatus.handover.status'),
      name: 'Handover',
    },
  ];

  const filterLabels = {
    signedDate: 'signedDate',
    signedAfter: 'signedDateAfter',
    signedBefore: 'signedDateBefore',
    investmentTotal: 'investmentTotal',
    investmentTotalMin: 'investmentTotalMin',
    investmentTotalMax: 'investmentTotalMax',
    status: 'frontendStatus',
  };

  return (
    <>
      <Search input="search" defaultField="search" label={translate('filters.labels.investments')} name="search" />
      <FilterPopup>
        <FilterMultiSelect
          label={translate('studioInvestmentsTable.product')}
          placeholder={translate('filters.multiselect.placeholderProduct')}
          options={productsFilter}
          input="tokenId"
          defaultField="tokenId"
        />
        <FilterMultiSelect
          label={translate('studioInvestmentsTable.distributionPlatform')}
          options={distributionPlatforms}
          placeholder={translate('filters.multiselect.placeholderPlatforms')}
          input="distributionPlatformId"
          defaultField="distributionPlatformId"
        />
        <FilterRange
          label={translate('filters.labels.signedDate')}
          inputNames={[filterLabels.signedAfter, filterLabels.signedBefore]}
          filterName={filterLabels.signedDate}
        >
          <FilterDateOption
            input={filterLabels.signedAfter}
            pairedInput={filterLabels.signedBefore}
            label={null}
            defaultField={filterLabels.signedAfter}
            maxDate={useFilterValue(filterLabels.signedBefore) as Date}
            name={filterLabels.signedDate}
          />
          <FilterDateOption
            input={filterLabels.signedBefore}
            pairedInput={filterLabels.signedAfter}
            label={null}
            defaultField={filterLabels.signedBefore}
            minDate={useFilterValue(filterLabels.signedAfter) as Date}
            name={filterLabels.signedDate}
          />
        </FilterRange>
        <FilterRange
          label={translate('filters.labels.investmentAmount')}
          inputNames={[filterLabels.investmentTotalMin, filterLabels.investmentTotalMax]}
          filterName={filterLabels.investmentTotal}
        >
          <FilterInputOption
            input={filterLabels.investmentTotalMin}
            pairedInput={filterLabels.investmentTotalMax}
            type="number"
            label={null}
            defaultField={filterLabels.investmentTotalMin}
            name={filterLabels.investmentTotal}
          />
          <FilterInputOption
            input={filterLabels.investmentTotalMax}
            pairedInput={filterLabels.investmentTotalMin}
            type="number"
            label={null}
            defaultField={filterLabels.investmentTotalMax}
            name={filterLabels.investmentTotal}
          />
        </FilterRange>
        <FilterMultiGroupOption
          input={filterLabels.status}
          defaultField={filterLabels.status}
          label={translate('studioInvestmentsTable.status')}
          options={investmentStatusFilterOptions}
        />
      </FilterPopup>
    </>
  );
};
export default IssuerInvestmentsFilters;
