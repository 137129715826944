import React, { FunctionComponent, useCallback, useEffect, useMemo, useState } from 'react';
import BoxedContent from 'ui/molecules/boxed-content';
import useApiCall from 'hooks/use-api-call';
import { AdminApi, InvestmentInvitation } from 'api';
import LoadingRing from 'ui/atoms/loading-ring';
import Translate from 'ui/atoms/translate';
import useClipboard from 'ui/hooks/use-clipboard';
import makeLink from 'helper/make-link';
import { INVESTMENT_ROUTES } from 'subapps/investment/pages/routes.config';
import { compact } from 'lodash';
import ProductInvitationExtendedDetailsCard from 'apps/issuer/pages/product-details/invitations/invitation-details/product-invitation-card';

const mapInvestmentInvitationStatus = (hasInvestment?: boolean) => {
  if (hasInvestment) return 'committed';
  return 'new';
};

const BoxedIssuerInvestmentInvitation: FunctionComponent<
  InvestmentInvitation & {
    onDeactivated?: ({ productId }: { productId: string }) => void;
    issuersCanManage?: boolean;
  }
> = (props) => {
  const { issuersCanManage, onDeactivated = () => {}, ...invitationProps } = props;
  const [invitation, setInvitation] = useState<InvestmentInvitation>(invitationProps);

  const {
    id,
    distributionPlatformDetails,
    maxNumberOfTokens,
    pricePerToken,
    country,
    email,
    token,
    ruleType,
    name,
    investment,
    createdAt,
    signedPercentage,
  } = invitation;

  const { copied, copy } = useClipboard();

  const url = makeLink(INVESTMENT_ROUTES.investment, { invitationId: id }, true);

  useEffect(() => {
    const { issuersCanManage, ...invitationProps } = props;
    setInvitation(invitationProps);
  }, [props]);

  const { withApi, makeAuthenticatedApi, loading } = useApiCall(false);

  const api: AdminApi = useMemo(() => makeAuthenticatedApi(AdminApi), [makeAuthenticatedApi]);

  const deactivate = useCallback(() => {
    withApi(async () => {
      if (id) {
        await api.adminInvitationsDestroy({ id });
        onDeactivated({ productId: token?.id });
      }
    });
  }, [withApi, api, id, onDeactivated]);

  return (
    <BoxedContent
      releaseSpaceWhenTitleIsEmpty={true}
      actionButtons={
        issuersCanManage
          ? compact([
              {
                name: 'copy',
                disabled: copied,
                content: <Translate name={`copy.link.${copied ? 'copied' : 'copy'}`} />,
                variant: 'primary',
                onClick: () => copy(url),
              },
              {
                name: 'deactivate',
                content: <Translate name="investmentInvitation.deactivate" />,
                variant: 'link',
                disabled: !!investment,
                loading,
                onClick: deactivate,
              },
            ])
          : compact([
              {
                name: 'copy',
                disabled: copied,
                content: <Translate name={`copy.link.${copied ? 'copied' : 'copy'}`} />,
                variant: 'primary',
                onClick: () => copy(url),
              },
            ])
      }
    >
      {loading && <LoadingRing />}
      {!loading && (
        <ProductInvitationExtendedDetailsCard
          id={id}
          distributionPlatform={distributionPlatformDetails.platformName}
          maxNumberOfTokens={maxNumberOfTokens}
          pricePerToken={pricePerToken}
          country={country as any}
          email={email}
          status={mapInvestmentInvitationStatus(!!investment)}
          ruleType={ruleType}
          token={token}
          name={name}
          signedPercentage={signedPercentage}
          createdAt={createdAt}
        />
      )}
    </BoxedContent>
  );
};

export default BoxedIssuerInvestmentInvitation;
