import React, { FunctionComponent, ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import InfoGrid, { InfoField } from 'ui/molecules/info-grid';
import useIsMedia, { DEVICES } from 'ui/hooks/use-is-media';
import { DistributionPlatformsApi, Money } from 'api';
import Translate from 'ui/atoms/translate';
import Number from 'ui/atoms/number';
import Currency from 'ui/atoms/currency';
import PlaceholderFallback from 'ui/atoms/placeholder-fallback';
import Header from 'ui/atoms/header';
import CopyButton from 'ui/molecules/copy-button';
import Spacer from 'ui/atoms/spacer';
import useApiCall from 'src/hooks/use-api-call';

export interface StudioInvestmentInvestorDetailsProps {
  /** Additional classes. */
  className?: string;
  title?: ReactNode;
  name: string;
  email: string;
  investorId?: string;
  activeInvestmentsNo: number | null;
  activeInvestmentsSum: Money | null;
  isLegalPerson: boolean;
  custodianBank?: boolean;
  distributionPlatformId?: string;
}

const StudioInvestmentInvestorDetails: FunctionComponent<StudioInvestmentInvestorDetailsProps> = (props) => {
  const {
    name,
    email,
    investorId,
    activeInvestmentsNo,
    activeInvestmentsSum,
    title,
    isLegalPerson,
    custodianBank,
    distributionPlatformId,
  } = props;

  const [distributionPlatform, setDistributionPlatform] = useState('');

  const isPhone = useIsMedia([DEVICES.phone]);

  const { withApi, makeAuthenticatedApi } = useApiCall();

  const distributionApi: DistributionPlatformsApi = useMemo(
    () => makeAuthenticatedApi(DistributionPlatformsApi),
    [makeAuthenticatedApi],
  );

  const getDistributionPlatformName = useCallback(() => {
    if (!distributionPlatformId || distributionPlatformId?.length === 0) return;

    withApi(async () => {
      try {
        const { platformName } = await distributionApi.distributionPlatformsRetrieve({ id: distributionPlatformId });
        setDistributionPlatform(platformName);
      } catch (e) {
        console.error(e);
      }
    });
  }, [distributionApi, distributionPlatformId]);

  useEffect(() => {
    getDistributionPlatformName();
  }, [withApi, distributionApi, distributionPlatformId]);

  return (
    <>
      {title && <Header size="small">{title}</Header>}
      {!title && investorId && (
        <>
          <InfoField title={name}>
            <p>
              <Translate name="studioInvestmentInvestorDetails.investorId" />
              <span>: {investorId} </span>
              <CopyButton valueToCopy={investorId} />
            </p>
          </InfoField>
          <Spacer y={4} />
        </>
      )}
      <InfoGrid columns={isPhone ? 1 : 3}>
        <InfoField title={<Translate name="common.emailShort" />}>{email}</InfoField>
        <InfoField title={<Translate name="studioInvestmentInvestorDetails.investments" />}>
          <PlaceholderFallback>
            {activeInvestmentsNo !== undefined && activeInvestmentsNo !== null && (
              <Number>{activeInvestmentsNo}</Number>
            )}
          </PlaceholderFallback>
        </InfoField>
        <InfoField title={<Translate name="studioInvestmentInvestorDetails.totalAmount" />}>
          <PlaceholderFallback>
            {activeInvestmentsSum && <Currency>{activeInvestmentsSum}</Currency>}
          </PlaceholderFallback>
        </InfoField>
        {distributionPlatform.length > 0 && (
          <InfoField title={<Translate name="studioInvestmentInvestorDetails.distributionPlatform" />}>
            <PlaceholderFallback>{distributionPlatform}</PlaceholderFallback>
          </InfoField>
        )}
        {isLegalPerson && (
          <InfoField title={<Translate name="studioInvestmentInvestorDetails.legalPerson" />}>
            <Translate
              name={`studioInvestmentInvestorDetails.${custodianBank ? 'custodianBank' : 'noCustodianBank'}`}
            />
          </InfoField>
        )}
      </InfoGrid>
    </>
  );
};

export default StudioInvestmentInvestorDetails;
