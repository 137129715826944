import React, { FunctionComponent } from 'react';

import WithAuthenticatedPage from 'core/auth/components/with-authenticated-page';
import { AppType } from 'core/auth/types';
import IssuerConfirmInvestmentsTableView from 'apps/issuer/shared/confirm-investments-table-view';
import { FilterDateOption, FilterInputOption, FilterPopup, FilterRange, Search } from 'ui/molecules/filtering';
import { useTranslateWithStringArgs } from 'ui/hooks/use-translate';
import { useProducts, useProductsFilter } from 'apps/issuer/hooks';
import FilterMultiSelect from 'ui/molecules/filtering/filter-popup/filter-multi-select';

const IssuerIncomingPaymentsFilters: FunctionComponent<{}> = () => {
  const translate = useTranslateWithStringArgs();

  const { tokens } = useProducts();
  const { productsFilter } = useProductsFilter(tokens);

  const filterLabels = {
    signedDate: 'signedDate',
    signedAfter: 'signedDateAfter',
    signedBefore: 'signedDateBefore',
    paymentTotal: 'paymentTotal',
    paymentTotalMin: 'paymentTotalMin',
    paymentTotalMax: 'paymentTotalMax',
  };

  return (
    <>
      <Search input="search" defaultField="investorName" label={translate('filters.labels.name')} name="search" />
      <FilterPopup>
        <FilterMultiSelect
          label={translate('studioInvestmentsTable.product')}
          placeholder={translate('filters.multiselect.placeholderProduct')}
          options={productsFilter}
          input="tokenId"
          defaultField="tokenId"
        />
        <FilterRange
          label={translate('filters.labels.signedDate')}
          inputNames={[filterLabels.signedAfter, filterLabels.signedBefore]}
          filterName={filterLabels.signedDate}
        >
          <FilterDateOption
            input={filterLabels.signedAfter}
            pairedInput={filterLabels.signedBefore}
            label={null}
            defaultField={filterLabels.signedAfter}
            name={filterLabels.signedDate}
          />
          <FilterDateOption
            input={filterLabels.signedBefore}
            pairedInput={filterLabels.signedAfter}
            label={null}
            defaultField={filterLabels.signedBefore}
            name={filterLabels.signedDate}
          />
        </FilterRange>
        <FilterRange
          label={translate('filters.labels.totalPayment')}
          inputNames={[filterLabels.paymentTotalMin, filterLabels.paymentTotalMax]}
          filterName={filterLabels.paymentTotal}
        >
          <FilterInputOption
            input={filterLabels.paymentTotalMin}
            pairedInput={filterLabels.paymentTotalMax}
            label={null}
            defaultField={filterLabels.paymentTotalMin}
            name={filterLabels.paymentTotal}
          />
          <FilterInputOption
            input={filterLabels.paymentTotalMax}
            pairedInput={filterLabels.paymentTotalMin}
            label={null}
            defaultField={filterLabels.paymentTotalMax}
            name={filterLabels.paymentTotal}
          />
        </FilterRange>
      </FilterPopup>
    </>
  );
};

const IssuerIncomingPaymentsPage: FunctionComponent<{}> = () => {
  return (
    <IssuerConfirmInvestmentsTableView
      type="confirmPayments"
      filters={<IssuerIncomingPaymentsFilters />}
      defaultLimit={50}
    />
  );
};

export default WithAuthenticatedPage(IssuerIncomingPaymentsPage, AppType.ISSUER);
