import React, { FunctionComponent, useContext } from 'react';
import SummaryCard from 'src/subapps/investment/pages/investment/wizard-steps/summary/summary-card';
import LoadingRing from 'ui/atoms/loading-ring';
import useInvestment from 'hooks/use-investment';
import { KycStatusEnum, StepEnum } from 'api/models';
import WizardContext from 'libraries/wizard/wizard-context';
import Translate from 'ui/atoms/translate';
import ActionButtons from 'ui/molecules/action-buttons';
import Button from 'ui/atoms/button';
import useGoTo from 'hooks/use-go-to';
import { INVESTOR_ROUTES } from 'apps/investor/pages/routes.config';
import ConsiderationPeriod from './consideration-period';
import useInvestmentInvitation from 'subapps/investment/pages/investment/hooks/use-investment-invitation';
import WizardHeader from 'src/libraries/wizard/wizard-header';
import Header from 'src/ui/atoms/header';

const SummaryStep: FunctionComponent = () => {
  const { resourceId: invitationId, investmentId, isStepDone, nextStep } = useContext(WizardContext);
  const { invitation } = useInvestmentInvitation(invitationId);
  const { investment, issuer, step, error, token } = useInvestment(investmentId);
  const goToInvestments = useGoTo(INVESTOR_ROUTES.overview);

  // todo(geforcefan): we should think about error handling
  //  for our whole investment process, ux team will think about a solution
  if (error) return null;

  if (!investment || !issuer || !token) return <LoadingRing />;

  const { tokenPrice, fulfilledAmount, paymentCode } = investment;

  const { bankAccount } = issuer.person;

  const { accountHolder, iban, bic } = bankAccount as any;

  const paymentInformation =
    step && ([StepEnum.PAYMENT, StepEnum.FINAL] as StepEnum[]).includes(step)
      ? {
          accountHolder,
          iban,
          bic,
          referenceText: paymentCode,
        }
      : undefined;

  return (
    <>
      <WizardHeader />
      <Header size="large" spacing="xlarge">
        {paymentInformation ? (
          <Translate name="investmentSummary.offerAcceptedTitle" />
        ) : (
          <Translate name="investmentSummary.offerNotAcceptedTitle" />
        )}
      </Header>
      {invitation?.isEcsp &&
      step === StepEnum.WAITING_ACCEPTANCE &&
      investment.investorKyc === KycStatusEnum.SUCCESS ? (
        <ConsiderationPeriod
          amount={fulfilledAmount}
          tokenPrice={tokenPrice}
          investmentTotal={investment.investmentTotal}
          paymentTotal={investment.paymentTotal}
          accruedInterest={investment.accruedInterest}
          disagio={investment.disagio}
          agio={investment.agio}
          isAccruedInterestFixed={isStepDone || false}
          showAccruedInterest={token.calculateAccruedInterest}
          paymentInformation={paymentInformation}
          useUnits={token.useUnits}
          considerationStartedAt={investment.considerationStartedAt}
        />
      ) : (
        <SummaryCard
          isStepDone={isStepDone}
          amount={fulfilledAmount}
          tokenPrice={tokenPrice}
          investmentTotal={investment.investmentTotal}
          paymentTotal={investment.paymentTotal}
          accruedInterest={investment.accruedInterest}
          disagio={investment.disagio}
          agio={investment.agio}
          isAccruedInterestFixed={isStepDone || step === StepEnum.PAYMENT}
          showAccruedInterest={token.calculateAccruedInterest}
          paymentInformation={paymentInformation}
          useUnits={token.useUnits}
        />
      )}
      <ActionButtons>
        {!isStepDone && (
          <Button variant="secondary" size="large" fluid={true} onClick={() => goToInvestments()}>
            <Translate name="investmentSummary.toInvestmentOverview" />
          </Button>
        )}
        {isStepDone && (
          <Button variant="primary" size="large" fluid={true} onClick={nextStep}>
            <Translate name="common.continue" />
          </Button>
        )}
      </ActionButtons>
    </>
  );
};

export default SummaryStep;
